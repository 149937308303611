export const data = {
  panelImageName: "empresas_tecnologia-bg.jpg",
  divisorText: "Empresas de Prestação de Serviços",
  content: {
    imageName: "EmpresasDeTecnologia.jpg",
    title: "Porque ser nosso cliente?",
    text:
      "Atuamos no mercado auxiliando empresas nas áreas contábil, fiscal, trabalhista e tributário. <br /> " +
      "Por meio da dedicação e comprometimento da nossa equipe, você dispõe de uma linha completa de serviços que facilitam a sua administração financeira. <br /> " +
      "Conte com soluções seguras e tenha a contribuição certa para o crescimento do seu negócio.",
    options: [
      "Automação Agrícola",
      "Refrigeração & Climatização",
      "Tecnologia",
    ],
    contactText:
      "Tenha uma contabilidade personalizada, cadastre-se e receba nosso contato",
  },
}
